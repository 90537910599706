/* @import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,800;1,900&family=Montserrat:ital,wght@0,900;1,100;1,200;1,300;1,900&family=Roboto+Slab:wght@300;500;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,800;1,900&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Montserrat:ital,wght@0,900;1,100;1,200;1,300;1,900&family=Roboto+Slab:wght@300;500;800&display=swap');

html,body {
  font-family: 'DM Sans', sans-serif;
}

*,body {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}